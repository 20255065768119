import img1 from '../../src/images/data/packages_1.png';
import img2 from '../../src/images/data/packages_2.jpg';
import img3 from '../../src/images/data/packages_3.png';
import img4 from '../../src/images/data/packages_4.png';
import img5 from '../../src/images/data/packages_6.png';
import img6 from '../../src/images/data/packages_5.png';

import logo from '../../src/assets/logo.jpg';

const q = {
    data : {
        wp: {
            q: {
                dataHeader : {
                    home:true, 
                    back:true,
                    logo:logo,
                    buttons : [
                        {
                            links : '/tv/glass',
                            label : 'Discover Sky Glass'
                        }
                    ]
                },
                options : [
                    {
                        id: 'q-multiscreen',
                        text : '<h3>Sky TV</h3><p>Endless entertainment with over 100 channels not on Freeview plus over 500 Box Sets</p>',
                        image: img1
                    },
                    {
                        id: 'q-multiscredsdsen',
                        text : '<h3>Sky Sport</h3><p>Launch yourself into the biggest sporting events</p>',
                        image: img2
                    },
                    {
                        id: 'q-ent',
                        text : '<h3>Sky Cinema</h3><p>Lose yourself in an unforgettable cinematic universe</p>',
                        image: img3
                    },
                    {
                        id: 'q-test',
                        text : '<h3>Ultimate TV Add On</h3><p>Watch unlimited Netflix shows on any device with a Basic, Standard or Premium package</p>',
                        image: img4
                    },
                    {
                        id: 'q-apps',
                        text :'<h3>Sports Extra</h3><p>A hassle-free way to access all your favourite sports extra channels</p>',
                        image: img5
                    },
                    {
                        id: 'q-hd',
                        text : '<h3>Kids</h3><p>Entertain & educate the kids in one safe place</p>',
                        image: img6
                    },
                ]
            }
        },

    }
}

export default q;